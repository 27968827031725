<template>
    <div id="app" class="hide-toolbar">
        <vue-editor ref="quillEditor" v-model="editContent" :editorOptions="editorOption" ></vue-editor>
    </div>
</template>
 
<script>
import { VueEditor, Quill } from "vue2-editor";

export default {
    components: {
        VueEditor,
    },
    data() {
        return {
        quillInstance: null,
        editContent: "",
        editorOption: {
            theme: 'snow',
            readOnly: true,
            modules: {
            toolbar: {
                container: [
                ],
                // handlers: {
                //     image: () => {
                //         var range = this.quillInstance.getSelection();
                //         var value = prompt('please copy paste the image url here.');
                //         if(value){
                //             this.quillInstance.insertEmbed(range.index, 'image', value, Quill.sources.USER);
                //         }
                //     }
                // }
            },
            // imageResize: {},
            // imageResize: {modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]},
            },
        },
        };
    },
    mounted() {
        this.quillInstance = this.$refs.quillEditor.quill;
        this.editContent = this.content;
        // Quill.register("modules/imageResize", ImageResize);
    },
    props: ['content'],
    };
</script> 
<style>
    .hide-toolbar .ql-toolbar {
        padding: 0;
        border-bottom: hidden;
        display: none;
    }
</style>